<template>
  <div class="card">

    <div class="card-header header-elements-inline">
      <h5 class="card-title">Extrusion Day Report</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="modal"  @click="showFilterWindow"></a>
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>


    <table class="table table-bordered table-condensed table-columned table-hover">
        <thead>
        <tr>
<!--          <th class="footable-visible footable-first-column">Date</th>-->
          <th data-hide="phone" class="footable-visible">Shift</th>
          <th data-hide="phone" class="footable-visible">Operator</th>
          <th class="footable-visible">Machine</th>
          <th class="footable-visible">Quality</th>
          <th class="footable-visible">Color</th>
          <th class="footable-visible">Size</th>
          <th class="footable-visible text-right">No of Beams</th>
          <th class="footable-visible text-right">Prod In Kgs</th>
          <th class="footable-visible text-right">Reliance</th>
          <th class="footable-visible text-right">Haldia</th>
          <th class="footable-visible text-right">Waste</th>
<!--          <th class="footable-visible text-right">No of Empty Beams</th>-->
        </tr>
        </thead>
      <tbody>
        <tr v-for="det in deliveries">
          <td class="footable-visible">{{det.shift}}</td>
          <td class="footable-visible">{{det.employee}}</td>
          <td >{{det.machine}}</td>
          <td class="footable-visible">{{det.quality}}</td>
          <td class="footable-visible">{{det.color}}</td>
          <td class="footable-visible">{{det.yarn_size}}</td>
          <td class="footable-visible text-right">{{det.qty}}</td>
          <td class="footable-visible text-right"> <a href="#"> {{det.prod_kgs}} </a></td>
          <td class="footable-visible text-right">{{det.reliance_qty | weightFormat}}</td>
          <td class="footable-visible text-right">{{det.hladia_qty  | weightFormat}}</td>
          <td class="footable-visible text-right">{{det.yarn_waste_wgt}}</td>
<!--          <td class="footable-visible text-right">0</td>-->
        </tr>

      </tbody>
    </table>

    <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="425" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
            <h3 class="card-title">Filter</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">Date:</label>
              <div class="col-md-9">
                <input type="date" class="form-control"  v-model="entry_from" />
              </div>

            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">Quality:</label>
              <div class="col-md-9">
                <select id="cmbQuality" class="form-control" v-model="qua" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in qualities" v-bind:value="ledger.code">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">Color:</label>
              <div class="col-md-9">
                <select class="form-control" v-model="col" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in colors" v-bind:value="ledger.code">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">Extruder:</label>
              <div class="col-md-9">
                <select class="form-control" v-model="mac" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in machines" v-bind:value="ledger.code">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">Employee:</label>
              <div class="col-md-9">
                <select class="form-control" v-model="emp" >
                  <option value="0" selected>All</option>
                  <option v-for="ledger in emps" v-bind:value="ledger.code">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
          <i class="fab-icon-open icon-filter3"></i>
          <i class="fab-icon-close icon-filter4"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->


  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'ExtrusionDayReport',
    store,
    components: {

    },
    data () {
      return {
        extruders: [],
        mytable: {},
        deliveries: [],
        qualities: [],
        machines: [],
        emps: [],
        colors:[],
        entry_from: moment().format('YYYY-MM-DD'),
        col:0,
        qua:0,
        mac: 0,
        emp: 0,
      }
    },
    mounted () {
      try {
        this.$data.mytable = $('.table');

        this.showFilterWindow();

        this.loadQualities();
        this.loadColors();
        this.loadMachines();
        this.loadEmployees();

      }catch (e) {
        alert(e);
      }
    },
    filters:{
      weightFormat (value) {
        if( value !== 0)
          return value.toFixed(3);
        else
          return '';
      },
      dateFormatter(val){
        try {
          return val.slice(0, 10).split('-').reverse().join('-');
        } catch (ex) {

        }
        return '';
      },
      shiftFormatter(val){
        try {
          if(val == 2){
            return 'Day';
          }else if(val == 3){
            return 'Night';
          }
          return val;
        } catch (ex) {

        }
        return '';
      }
    },
    methods:{
      showFilterWindow(){
        this.$modal.show('filter-window');
      },
      closeWindow(){
        this.$router.push('/');
      },
      loadData() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$modal.hide('filter-window');
        self.$data.mytable.bootstrapTable('load',[]);
        self.$data.mytable.bootstrapTable('showLoading');

        self.$data.deliveries = [];
        //entry_from

        // alert(`${process.env.VUE_APP_ROOT_API}api/report/extrusion/dayreport?col=${self.$data.col}&qua=${self.$data.qua}&date=${moment(self.$data.entry_from).format('YYYY-MM-DD')}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/report/extrusion/dayreport?col=${self.$data.col}&qua=${self.$data.qua}&date=${moment(self.$data.entry_from).format('YYYY-MM-DD')}`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){

            if(_.isArray(resp.data)) {

              console.log( JSON.stringify(resp.data));
              self.$data.deliveries = resp.data;
            }

          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
        });

      },
      loadQualities() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.qualities = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){
            self.$data.qualities = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadColors() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.colors = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/colors`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.colors = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadMachines() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.machines = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.machines = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadEmployees() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.emps = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( resp.data != null){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
    }
  }
</script>

<style scoped>

</style>
